*[class*='--mt-0'] {
	margin-top: 0;
}

*[class*='--mb-40'] {
	margin-bottom: 40px;
}

@for $i from 1 through 20 {
	.mb-#{$i * 5} {
		margin-bottom: #{$i * 5}px !important;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-12 {
	margin-bottom: 12px !important;
}

.mb-16 {
	margin-bottom: 16px !important;
}

@for $i from 1 through 20 {
	.mt-#{$i * 5} {
		margin-top: #{$i * 5}px !important;
	}
}

.mt-0 {
	margin-top: 0 !important;
}

.mt-24 {
	margin-top: 24px !important;
}

@for $i from 1 through 20 {
	.px-#{$i * 5} {
		padding-left: #{$i * 5}px !important;
		padding-right: #{$i * 5}px !important;
	}
}

.gap-0 {
	gap: 0 !important;
}

@for $i from 1 through 20 {
	.gap-#{$i * 5} {
		gap: #{$i * 5}px !important;
	}
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left !important;
}

.justify-content-center {
	justify-content: center;
}

.font-weight-semibold {
	font-weight: 600;
}

.grid-column-span-2 {
	grid-column: span 2;
}

.child-select-block-mb-0 {
	.select-block {
		margin-bottom: 0 !important;
	}
}

.leading-1 {
	line-height: 1 !important;
}
